body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 1rem;
}

input[type="file"] {
  width: 100%;
}

.required-fields:before {
  content: " *";
  color: red;
}

.tox .tox-editor-header {
  z-index: 0 !important;
}

.table-responsive {
  overflow-x: visible !important;
}